import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import { Data, AppService } from '../../../app.service';
import { Settings, AppSettings } from '../../../app.settings';
import {Router} from '@angular/router';
import {Store} from '@ngxs/store';
import {SetFields} from '../../../store/app-actions';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html'
})
export class TopMenuComponent implements OnInit {
  public currencies = ['USD', 'EUR'];
  public currency: any;
  public flags = [
    { name: 'Armenia', lg: 'arm',  image: 'assets/images/flags/arm.svg'},
    { name: 'English', lg: 'en', image: 'assets/images/flags/gb.svg' },
    { name: 'Russian', lg: 'ru', image: 'assets/images/flags/ru.svg' }
  ];
  public flag: any;

  public settings: Settings;
  constructor(
      public appSettings: AppSettings,
      private translate: TranslateService,
      public router: Router,
      public store: Store,
      public appService: AppService) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.currency = this.currencies[0];
    this.flag = this.flags[0];
  }
  public signOut() {
    const data = {
      user: {
        token: this.store.snapshot().AppState.authToken
      }
    };
    this.appService.logOut(data).subscribe(res => {
      this.store.dispatch(new SetFields({
        // @ts-ignore
        authToken: null
      }));
      this.router.navigateByUrl('/sign-in');
    });
  }
  public changeCurrency(currency){
    this.currency = currency;
  }

  public changeLang(flag){
    this.flag = flag;
    this.translate.use(flag.lg);

  }
}
