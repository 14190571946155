import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Settings, AppSettings } from './app.settings';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngxs/store';
import {AppService} from '@app/app.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  loading: boolean = false;
  public settings: Settings;
  constructor(public appSettings: AppSettings,
              public router: Router,
              public appService: AppService,
              public store: Store,
              private translate: TranslateService){
    this.settings = this.appSettings.settings;
    translate.setDefaultLang('arm');
  }

  ngOnInit() {
    this.appService.getAllCategories().subscribe(res => {
      // @ts-ignore
      this.appService.categories = res;
    });
  }

  ngAfterViewInit(){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
          window.scrollTo(0, 0);
      }
    });
  }
}
