import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Data, AppService } from '../../app.service';
import { Product } from '../../app.models';
import {Store} from "@ngxs/store";

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class ControlsComponent implements OnInit {
  @Input() product: Product;
  @Input() type: string;
  @Output() onOpenProductDialog = new EventEmitter();
  @Output() onQuantityChange = new EventEmitter();
  public count: number = 1;
  public align = 'center center';
  constructor(public appService: AppService, public store: Store, public snackBar: MatSnackBar, ) { }

  ngOnInit() {
    if (this.product){
      if (this.product.cartCount > 0){
        this.count = this.product.cartCount;
      }
    }
    this.layoutAlign();
  }

  public layoutAlign(){
    if(this.type === 'all'){
      this.align = 'space-between center';
    }
    else if(this.type === 'wish'){
      this.align = 'start center';
    }
    else{
      this.align = 'center center';
    }
  }

  public increment(count){
      // @ts-ignore
      const price =
          this.product.discount > 0 ?
              this.product.price - this.product.price * (this.product.discount / 100) :
              this.product.price;

      this.count++;
      const obj = {
        // @ts-ignore
        productId: this.product._id,
        soldQuantity: this.count,
        total: this.count * price
      };
      this.changeQuantity(obj);
  }

  public decrement(count){
    if (this.count > 1){
      this.count--;
      // @ts-ignore
      const price = this.product.discount > 0 ?
          this.product.price - this.product.discount * (this.product.price / 100) : this.product.price;

      const obj = {
        // @ts-ignore
        productId: this.product._id,
        soldQuantity: this.count,
        // @ts-ignore
        total: this.count * price
      };
      this.changeQuantity(obj);
    }
  }

  public addToCompare(product: Product){
    this.appService.addToCompare(product);
  }

  public addToWishList(product: Product){
    this.appService.addToWishList(product);
  }

  public addToCart(product: Product){
    // console.log(product)
    let currentProduct = this.store?.snapshot()?.AppState?.card?.filter(item => item.id  === product.id)[0];
    if (currentProduct){
        product.cartCount = currentProduct.cartCount + this.count;
    }
    else{
      product.cartCount = this.count;
    }
    this.appService.addToCart(product);
  }

  public openProductDialog(event){
    this.onOpenProductDialog.emit(event);
  }

  public changeQuantity(value){
      this.onQuantityChange.emit(value);
  }

}
