import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterById'
})
export class FilterByIdPipe implements PipeTransform {
  transform(items, id?) {
    // @ts-ignore
    if (items && items.constructor === Array) {
      return items.filter(item => item._id === id)[0];
    }
  }
}
