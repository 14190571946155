import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { AppService } from './app.service';
import { Router } from '@angular/router';
import { Store, Select } from '@ngxs/store';
import { AppState } from './store/app-state';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    @Select(AppState.GetStore) state: any;
    // @Select(AppState.SelectRefreshToken) RefreshToken: Observable<string[]>;

    constructor(public appService: AppService, private router: Router, private store: Store) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request && request.headers) {
            if (this.store.snapshot().AppState.authToken) {
                request = request.clone({
                    headers: request.headers.append('Authorization', this.store.snapshot().AppState.authToken)
                });
            }
        }
        return next.handle(request);
    }
}
