import {Component, OnInit, HostListener, ViewChild, AfterViewInit} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Settings, AppSettings } from '../app.settings';
import { AppService } from '../app.service';
import { Category, Product } from '../app.models';
import { SidenavMenuService } from '../theme/components/sidenav-menu/sidenav-menu.service';
import {Store} from '@ngxs/store';
import {FormBuilder, Validators, FormGroup} from '@angular/forms';
import { ReactiveFormsModule, FormControl, FormsModule } from '@angular/forms';


@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  providers: [ SidenavMenuService ]
})
export class PagesComponent implements OnInit, AfterViewInit {
  public showBackToTop: boolean = false;
  public categories: any;
  public category: Category;
  public sidenavMenuItems: Array<any>;
  @ViewChild('sidenav', { static: true }) sidenav: any;

  public settings: Settings;
  constructor(public appSettings: AppSettings,
              public appService: AppService,
              public store: Store,
              public formBuilder: FormBuilder,
              public sidenavMenuService: SidenavMenuService,
              public router: Router) {
    this.settings = this.appSettings.settings;
  }
  change($event) {
    this.router.navigateByUrl(`/products/product/search/${$event.target.value}`);
  }
  ngOnInit() {
    this.getCategories();
    this.sidenavMenuItems = this.sidenavMenuService.getSidenavMenuItems();
  }
  public signOut() {
    this.router.navigateByUrl('/sign-in');
  }
  public openCard() {
    this.router.navigateByUrl('/cart');
  }
  public getCategories() {
    this.appService.getAll('categories').subscribe(res => {
      // @ts-ignore
      this.categories = res.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));

      this.category = res[0];
    });
  }

  public changeCategory(event){
    if (event.target){
      this.category = this.categories.filter(category => category.name === event.target.innerText)[0];
    }
    if (window.innerWidth < 960){
      this.stopClickPropagate(event);
    }
  }

  public remove(product) {
      const index: number = this.appService.Data.cartList.indexOf(product);
      if (index !== -1) {
          this.appService.Data.cartList.splice(index, 1);
          this.appService.Data.totalPrice = this.appService.Data.totalPrice - product.newPrice * product.cartCount;
          this.appService.Data.totalCartCount = this.appService.Data.totalCartCount - product.cartCount;
          this.appService.resetProductCartCount(product);
      }
  }

  public clear(){
    this.appService.Data.cartList.forEach(product => {
      this.appService.resetProductCartCount(product);
    });
    this.appService.Data.cartList.length = 0;
    this.appService.Data.totalPrice = 0;
    this.appService.Data.totalCartCount = 0;
  }

  public changeTheme(theme){
    this.settings.theme = theme;
  }

  public stopClickPropagate(event: any){
    event.stopPropagation();
    event.preventDefault();
  }

  public search(){}
  public scrollToTop(){
    const scrollDuration = 200;
    const scrollStep = -window.pageYOffset  / (scrollDuration / 20);
    const scrollInterval = setInterval(() => {
      if (window.pageYOffset !== 0){
         window.scrollBy(0, scrollStep);
      }
      else{
        clearInterval(scrollInterval);
      }
    }, 10);
    if (window.innerWidth <= 768){
      setTimeout(() => {
        window.scrollTo(0, 0);
      });
    }
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    ($event.target.documentElement.scrollTop > 300) ? this.showBackToTop = true : this.showBackToTop = false;
  }

  ngAfterViewInit(){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.sidenav.close();
      }
    });
    this.sidenavMenuService.expandActiveSubMenu(this.sidenavMenuService.getSidenavMenuItems());
  }

  public closeSubMenus() {
    if (window.innerWidth < 960) {
      this.sidenavMenuService.closeAllSubMenus();
    }
  }
}
