import { State, Selector, Action, StateContext } from '@ngxs/store';
import {
    SetFields,
} from './app-actions';
import { patch, append, removeItem, insertItem, updateItem } from '@ngxs/store/operators';

export interface AppStateModel {
    authToken: string;
    refreshToken: string;
    language: string;
    lectorList: string[];
    currentBuyItem: {};
    card: [];
    localBasket: {
        order_items: [],
        total: 0,
        cardId: 0,
    };
    categoryList: any[];
    totalPrice: 0;
    totalCartCount: 0;
    user: {
        email: '',
        name: '',
        id: 0,
        coins: number;
        birthdate: '',
    };
}
@State<AppStateModel>({
    name: 'AppState',
    defaults: {
        authToken: null,
        refreshToken: null,
        language: 'ru',
        totalPrice: 0,
        totalCartCount: 0,
        card: [],
        lectorList: [],
        currentBuyItem: {},
        localBasket: {
            order_items: [],
            total: 0,
            cardId: 0,
        },
        categoryList: [],
        user: {
            email: '',
            coins: 0,
            name: '',
            id: 0,
            birthdate: '',
        }
    }
})
export class AppState {
    @Selector() static GetStore(state: AppStateModel): AppStateModel {
        return state;
    }
    @Selector() static authToken(state: AppStateModel): string {
        return state.authToken;
    }
    @Action(SetFields)
    SetFields(ctx: StateContext<AppStateModel>, fields: SetFields) {
        const tmpState = ctx.getState();
        ctx.setState(
            patch({ ...fields.field })
        );
    }
}


