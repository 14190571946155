import { Component, OnInit, Input } from '@angular/core';
import {AppService} from '@app/app.service';
import {Product} from "@app/app.models";
import {SwiperConfigInterface} from "ngx-swiper-wrapper";
import {AppSettings, Settings} from "@app/app.settings";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ProductDialogComponent} from "@app/shared/products-carousel/product-dialog/product-dialog.component";

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit {
  public config: SwiperConfigInterface = {};
  public settings: Settings;
  public productsList: any;
  public saledProductsList: any;
  detailes: any;
  constructor(public appSettings: AppSettings, public appService: AppService, public dialog: MatDialog, private router: Router) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.appService.getProductsPaginated({inGeneral: true}, 0, 15 ).subscribe(res => {
      // @ts-ignore
      this.productsList = res.data;
      // @ts-ignore
    });
    this.appService.getProductsPaginated({discount: { $gt: 0 }}, 0, 15 ).subscribe(res => {
      // @ts-ignore
      this.saledProductsList = res.data;
    });
  }
  ngAfterViewInit(){
    this.config = {
      observer: true,
      slidesPerView: 1,
      spaceBetween: 16,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 4,
        },
        1500: {
          slidesPerView: 5,
        }
      }
    }
  }

  public openProductDialog(product) {
    const dialogRef = this.dialog.open(ProductDialogComponent, {
      data: product,
      panelClass: 'product-dialog',
      direction: (this.settings.rtl) ? 'rtl' : 'ltr'
    });
    dialogRef.afterClosed().subscribe(product => {
      if (product){
        this.router.navigate(['/products', product.id, product.name]);
      }
    });
  }

}
