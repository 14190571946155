import { Component, OnInit, Input} from '@angular/core';
import {Store} from '@ngxs/store';
import {AppService} from '@app/app.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  categories: any;
  constructor( public store: Store, public appService: AppService) { }
  ngOnInit() {
    this.appService.getAll('categories').subscribe(res => {
      this.categories = res;
    });
  }

  openMegaMenu(){
    let pane = document.getElementsByClassName('cdk-overlay-pane');
    [].forEach.call(pane,  function (el) {
        if (el.children.length > 0){
          if (el.children[0].classList.contains('mega-menu')){
            el.classList.add('mega-menu-pane');
          }
        }
    });
  }

}
